@use '../includes' as *;

.transcript {
  --font-size: #{$f16};

  position: relative;
  max-width: 840px;

  @include breakpoint($sm) {
    padding-block-start: $g48;
  }

  @include breakpoint($sm-md-only) {
    --font-size: #{$f12};
  }
}

.transcript_over_link {
  display: none;
  position: absolute;
  inset-block-start: $g16;
  inset-inline-end: 0;
  gap: $g8;
  font-size: var(--font-size);
  font-weight: $bold;

  @include breakpoint($sm) {
    display: flex;
  }

  svg {
    width: 14px;
    transition: fill var(--duration-half) ease-in;
    fill: $white;
  }

  &:hover,
  &:focus {
    color: $pebble;

    svg {
      fill: $pebble;
    }
  }
}

.transcript_text {
  height: 100vh;
  max-height: 740px;
  padding-block: $g16 0;
  padding-inline: $g24;
  overflow-y: scroll;
  line-height: 1.25;

  p {
    margin-block-end: $g20;
    font-size: var(--font-size);

    @include breakpoint($sm) {
      margin-block-end: $g12;
    }

    @include breakpoint($md) {
      margin-block-end: $g20;
    }

    @include breakpoint($lg) {
      margin-block-end: $g24;
    }
  }
  // Custom Scrollbar styling

  // Chrome & Firefox have the standards-based scrollbar rules
  // https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Scrollbars
  @supports (scrollbar-color: initial) {
    scrollbar-color: $pebble transparent;
  }

  // Safari uses -webkit pseudeo elements
  // https://css-tricks.com/almanac/properties/s/scrollbar/
  @supports not (scrollbar-color: initial) {
    &::-webkit-scrollbar {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      min-height: 40px;
      border: 4px solid transparent;
      border-radius: 15px;
      background-color: $pebble;
    }
  }
}

@use '../../../../components/includes' as *;

.about_tab {
  display: grid;
  gap: var(--spacing-block);
  margin-block-end: var(--spacing-block);

  & > [class*='highlight'] {
    margin-block-end: 0;
  }
}

.about_tab_upper_content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-block);

  @include breakpoint($sm) {
    display: grid;
    grid-template-areas: 'howToWatch contentMetaData';
    grid-template-columns: minmax(auto, 660px) 270px;
    justify-content: space-between;

    &:has(.supporting_text) {
      grid-template-areas:
        'howToWatch      contentMetaData'
        'supportingText  contentMetaData';
    }

    &:has(.supporting_links) {
      grid-template-areas:
        'howToWatch      contentMetaData'
        'supportingLinks contentMetaData';
    }

    &:has(.supporting_text):has(.supporting_links) {
      grid-template-areas:
        'howToWatch      contentMetaData'
        'supportingText  contentMetaData'
        'supportingLinks contentMetaData';
    }
  }
}

.content_metadata {
  grid-area: contentMetaData;
}

.how_to_watch {
  grid-area: howToWatch;
}

.supporting_text {
  grid-area: supportingText;
}

.supporting_links {
  grid-area: supportingLinks;
}

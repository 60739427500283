@use '../includes' as *;

.content_nav {
  display: none;
  position: fixed;
  inset: var(--navigation-height) 0 auto;
  z-index: 2;
  width: 100vw;
  height: 180px;
  transition-property: translate;
  transition-duration: var(--duration-long);
  background: linear-gradient(
    to bottom,
    rgba($midnight, 100%) 0%,
    rgba($midnight, 88%) 20%,
    rgba($midnight, 36%) 67%,
    rgba($midnight, 0%) 100%
  );
  font-size: $f14;
  font-weight: $normal;
  pointer-events: none;

  @include breakpoint($utility-nav-bp) {
    display: block;
  }
}

.content_nav_list {
  display: flex;
  justify-content: space-between;
  gap: $g32;
  padding-block-start: $g16;
  // gets the icons to line up
  padding-inline: var(--spacing-inline) calc(var(--spacing-inline) + 8px);
  list-style: none;
  pointer-events: auto;
}

.content_nav_item {
  display: flex;
  gap: $g4;

  &:nth-of-type(6) {
    display: none;

    @include breakpoint(1220px) {
      display: flex;
    }
  }

  &:nth-of-type(7) {
    display: none;

    @include breakpoint(1420px) {
      display: flex;
    }
  }

  &:nth-of-type(8) {
    display: none;

    @include breakpoint(1520px) {
      display: flex;
    }
  }

  &:nth-of-type(9) {
    display: none;

    @include breakpoint(1620px) {
      display: flex;
    }
  }
}

.passport_compass {
  width: 16px;
  height: 16px;
}

.content_nav_link {
  display: block;
  position: relative;
  padding-block-end: $g8;
  transition-property: color;
  transition-duration: var(--duration);
  transition-timing-function: ease-in;
  cursor: pointer;

  &::before {
    @include current-page-nav-underline;
  }

  &:hover,
  &:focus {
    color: $pebble;
    text-decoration: none;
  }

  &:focus-visible {
    outline: $focusstatelight;
  }
}

.content_nav__scrolled {
  translate: 0 -180px;
}

.content_nav_link__active {
  position: relative;

  &,
  &:link,
  &:visited {
    color: $white;
  }

  &::before {
    @include current-page-nav-underline($teal);
  }
}

.all_genres_menu {
  inset-block-start: 36px;
  inset-inline-end: 0;
  opacity: 0;
}

.all_genres_nav_item {
  position: relative;

  @include menu-triangle(20px, -10px);

  // when the wrapper div is hovered
  &:hover,
    // or the donate button is hovered
    &:has(button:hover),
    // or the donate menu is hovered
    &:has(.all_genres_menu:hover),
    // or if there is a link in the user menu that is in focus
    &:has(.all_genres_menu:focus-within) {
    // then we want to make see the triangle
    &::before {
      opacity: 1;
    }

    // and show the donate menu
    .all_genres_menu {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

@use '../../../components/includes' as *;

.text_input {
  display: block;
  position: relative;
  max-width: $max-width;
  color: $midnight;

  svg,
  svg path,
  + * svg path {
    fill: white;
  }

  label {
    // hides the label, but screenreaders still pick it up
    visibility: hidden;
  }

  @include breakpoint($xxxxs) {
    margin-inline: calc(var(--spacing-inline) / 2);
  }

  @include breakpoint($smmd) {
    margin-block: $g24;
    margin-inline: var(--spacing-inline);
  }
}

.title_filter_input {
  width: 100%;
  margin-inline-end: 12px;
  padding: 6px 30px 6px $g8;
  padding-inline-start: 43px;
  border: 2px solid $white;
  border-radius: 7.2px;
  background-color: transparent;
  color: var(--text-color);

  @include h2;

  @media screen and (min-device-pixel-ratio: 0) {
    input {
      font-size: 16px;
    }
  }

  @include breakpoint($xxxxs) {
    height: 40px;
    padding-inline-start: 15px;
    border-width: 1px;
  }

  @include breakpoint($sm) {
    height: 48px;
    padding-inline-start: 20px;
  }

  @include breakpoint($smmd) {
    height: 58px;
    padding-inline-start: 25px;
    border-width: 2px;
  }

  @include breakpoint($lg) {
    height: 80px;
  }

  &::placeholder {
    color: $pebble;

    @include breakpoint($xxxxs) {
      font-size: $f12;
    }

    @include breakpoint($smmd) {
      font-size: $f16;
    }

    @include breakpoint($md) {
      font-size: $f26;
    }
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: 3px solid var(--focus-color);
  }
}

.text_input_icon {
  position: absolute;
  pointer-events: none;

  @include breakpoint($xxxxs) {
    inset-block-start: 25px;
    inset-inline-end: 25px;
    width: 15px;
    height: 15px;
  }

  @include breakpoint($sm) {
    inset-block-start: 30px;
    inset-inline-end: 30px;
    width: 15px;
    height: 15px;
  }

  @include breakpoint($smmd) {
    inset-block-start: 30px;
    inset-inline-end: 30px;
    width: 24px;
    height: 24px;
  }

  @include breakpoint($md) {
    inset-block-start: 27px;
    inset-inline-end: 27px;
    width: 30px;
    height: 30px;
  }

  @include breakpoint($lg) {
    inset-block-start: 35px;
    inset-inline-end: 35px;
    width: 34px;
    height: 34px;
  }
}

.text_input_icon_close {
  pointer-events: auto;

  &:hover {
    cursor: pointer;
  }
}

@use '../includes' as *;

// logic around how to show / hide this menu is in SignInOrProfile.module.scss
.sign_in_menu {
  @include menu-background;
  @include body-copy-1;

  position: absolute;
  inset: calc(var(--navigation-height)) -36px auto auto;
  width: 340px;
  border-radius: 6px;
  text-align: center;
  pointer-events: none;
}

.sign_in_menu_inner {
  padding: $g32 $g28;
}

@use '../includes' as *;

.my_list_menu_item {
  position: relative;

  &.has_drop_down {
    @include menu-triangle(36px);
  }
}

.my_list_link {
  display: flex;
  align-items: center;
  gap: $g8;
  text-wrap: nowrap;
}

.my_list_icon {
  width: 21px;
  height: 16px;
}

.my_list_menu {
  @include menu-background;

  display: none;
  position: absolute;
  // -146 is a magic number that centers this drop down
  // under the "My List" link
  inset: calc(var(--navigation-height)) -146px auto auto;
  width: 340px;
  transition: opacity var(--duration-half) ease-in;
  border-radius: 6px;
  opacity: 0;
  text-align: center;
  // fix for preventing hover happening when near but not on the menu
  pointer-events: none;
}

.my_list_menu_inner {
  padding: $g28 $g28 $g20 $g28;
}

.my_list_menu_intro {
  @include body-copy-1;

  margin-block-end: $g16;
}

@include bp-utility-nav-mouse-only {
  .my_list_menu {
    display: block;
  }

  .my_list_menu_item {
    // when the wrapper div is hovered
    &:hover,
    // or the sign in button is hovered
    &:has(button:hover),
    // or the sign in menu is hovered
    &:has(.my_list_menu:hover),
    // or if there is a link in the user menu that is in focus
    &:has(.my_list_menu:focus-within) {
      // then we want to make see the triangle
      &::before {
        opacity: 1;
      }

      // and show the mylist menu
      .my_list_menu {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}

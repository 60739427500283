@use '../includes' as *;

$embed-bp: $xs;

// prevent scrolling in the background when the sign in modal is open
body:has(.embed_modal[open]) {
  overflow: hidden;
}

.embed_modal {
  width: 100vw;
  height: 100vh;
  padding-block-end: $g24;
  padding-inline: $g16;
  border-style: none;
  background: rgba($navy-blue, 0.8);
  backdrop-filter: blur($blur-radius-med);
  color: var(--text-color);

  @include breakpoint($embed-bp) {
    width: 560px;
    height: max-content;
    padding-inline: $g64;
    border-radius: 6px;
  }
}

.embed_modal__topbar {
  padding-block-start: $g112;
  padding-block-end: $g12;

  @include breakpoint($embed-bp) {
    padding-block-start: $g52;
    padding-block-end: $g16;
  }
}

.embed_modal__topbar_title {
  margin: 0;
  padding: 0;
  font-size: $f12;
  font-weight: $bold;

  @include breakpoint($embed-bp) {
    font-size: $f22;
  }
}

.embed_window__content {
  padding: $g24 $g48 $g16 $g48;
}

.embed_modal__btn_close {
  inset-block-start: $g24;
  inset-inline-end: $g24;

  svg {
    width: $g16;
    height: $g16;
  }
}

.embed_modal__dialog {
  max-width: 600px;
}

.embed_modal__info {
  display: grid;
  grid-template-areas:
    'image show-title'
    'image video-title'
    'image meta';
  grid-template-columns: 120px 1fr;
  // stylelint-disable-next-line
  grid-template-rows: 15px auto 1fr;
  align-content: start;
  column-gap: $g12;
  row-gap: $g4;
  margin-block-end: $g24;

  @include breakpoint($embed-bp) {
    grid-template-columns: 185px 1fr;
    column-gap: $g16;
    margin-block-end: $g32;
  }
}

.embed_modal__image {
  grid-area: image;
  width: 100%;
  margin-inline-end: $g20;
}

.embed_modal__show_title {
  grid-area: show-title;
  margin: 0;
  color: var(--text-color);
  font-size: $f12;
  font-weight: $bold;
  text-transform: uppercase;
}

.embed_modal__video_title {
  @include h5;

  grid-area: video-title;
  margin: 0;
  color: var(--link-color);
}

.embed_modal__meta {
  grid-area: meta;
  margin: 0;
  font-size: $f12;
  font-weight: $normal;
}

.embed_modal__iframe_title {
  margin-block-end: $g12;
  font-size: $f12;
  font-weight: $bold;

  @include breakpoint($embed-bp) {
    margin-block-end: $g16;
    font-size: $f18;
  }
}

.embed_modal__edit_dimensions {
  display: flex;
  align-items: center;
  margin-block-end: $g16;
  font-size: $f12;
  text-align: center;

  label {
    margin-block-end: 0;
    color: var(--text-color);
    font-size: $f12;
    font-weight: bold;

    &[for='embed-height'] {
      margin-inline-start: $g32;
    }
  }

  input {
    width: 50px;
    height: 36px;
    margin-inline: $g8;
    border: none;
    border-radius: 6px;
    background-color: rgb(255 255 255 / 20%);
    color: var(--text-color);
    text-align: center;
  }

  @include breakpoint($embed-bp) {
    font-size: $f16;

    label {
      margin-inline-end: $g4;
      font-size: $f16;
    }

    input {
      width: 60px;
    }
  }
}

.embed_modal__input_button {
  display: flex;
  width: 100%;
  margin-block-end: $g24;

  @include breakpoint($embed-bp) {
    margin-block-end: $g40;
  }
}

.embed_modal__text_for_copying {
  flex-grow: 1;
  padding: $g12 0 $g12 $g12;
  border: 0;
  border-radius: 6px 0 0 6px;
  background-color: rgb(255 255 255 / 20%);
  color: var(--text-color);
  font-size: $f12;

  @include breakpoint($embed-bp) {
    font-size: $f16;
  }
}

.embed_modal__copy_button {
  position: relative;
  width: 80px; // magic number - allows text to fit on one line and the button to not flex
  transition-duration: 0.15s;
  border: 0;
  border-radius: 0 6px 6px 0;
  background: $white;
  color: $pbs-blue;
  font-size: $f12;
  font-weight: bold;
  cursor: pointer;

  @include breakpoint($embed-bp) {
    font-size: $f16;
  }
}

@use '../includes' as *;
@use '../ContentMetadata/ContentMetadata.module.scss';

.copy_button {
  display: inline-flex;
  align-items: center;
  gap: $g8;

  button {
    @include ContentMetadata.content-metadata-btn;
  }
}

.copy_button__copied_text {
  @include body-copy-2($pebble);

  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}

.show {
  opacity: 1;
}

@use '../../includes' as *;

.search_show_results_title {
  @include h2;

  margin-block-end: $g8;

  @include breakpoint($sm) {
    margin-block-end: $g12;
  }
}

div.search_results_show_row {
  // negative margins allow the posters to expand on hover without getting cropped
  margin-block-start: -20px;
  margin-inline-start: -20px;
  padding: 20px;
  overflow: hidden;

  @include breakpoint($below-sm) {
    margin-block-end: $g8;
  }
}
